var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offer-template page-wrapper" },
    [
      _c("router-view"),
      _c("OrganismSidebar", [
        _c("div", { staticClass: "menu" }, [
          _c(
            "div",
            { staticClass: "hidden-lg" },
            _vm._l(_vm.sectionsData, function(section, i) {
              return _c(
                "router-link",
                {
                  key: i,
                  staticClass: "sidebar-link navigation-link",
                  attrs: {
                    "active-class": "active",
                    tag: "a",
                    to: {
                      name: "OfferView",
                      params: { offerId: _vm.offer.uuid },
                      hash: "#" + section.hashAnchor
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(section.menuLabel) + "\n        "
                  )
                ]
              )
            }),
            1
          )
        ]),
        _c("div", { staticClass: "social-links" }, [
          _c("a", { attrs: { href: _vm.instagramUrl } }, [_vm._v("Instagram")]),
          _c("a", { attrs: { href: _vm.linkedinUrl } }, [_vm._v("Linkedin")]),
          _c("a", { attrs: { href: _vm.facebookUrl } }, [_vm._v("Facebook")])
        ])
      ]),
      _c("OrganismMenuBarOffer", [
        _c(
          "div",
          { staticClass: "hidden-xs" },
          _vm._l(_vm.sectionsData, function(section, i) {
            return _c(
              "router-link",
              {
                key: i,
                staticClass: "sidebar-link navigation-link",
                attrs: {
                  "active-class": "active",
                  tag: "a",
                  to: {
                    name: "OfferView",
                    params: { offerId: _vm.offer.uuid },
                    hash: "#" + section.hashAnchor
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(section.menuLabel) + "\n      ")]
            )
          }),
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }